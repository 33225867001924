import React from 'react';
import { Avatar, Popover } from 'antd';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import logo from 'public/images/user_icon.png';

import { UserMenu } from './Menu';

const UserAvatar = () => {
  const { t } = useTranslation('menu');
  return (
    <Popover
      overlayClassName="gx-popover-horizontal"
      placement="bottomRight"
      content={<UserMenu />}
      trigger="click"
    >
      <Avatar
        src={<Image src={logo} alt={t('Account Details')} />}
        className="gx-avatar gx-pointer"
      />
    </Popover>
  );
};
export default UserAvatar;
