import React from 'react';
import { Drawer, Layout } from 'antd';
import Link from 'next/link';
import Image from 'next/image';
import { Scrollbars } from 'react-custom-scrollbars';

import { MenuContent } from './Menu';
import { useLayout } from '../util/use-layout';

const SideBar = () => {
  const { collapsedMenu, setCollapsedMenu } = useLayout();

  return (
    <Layout.Sider
      data-cy="sidebar"
      className="gx-app-sidebar gx-collapsed-sidebar"
      trigger={null}
      collapsible
    >
      <Drawer
        placement="left"
        closable={false}
        onClose={() => setCollapsedMenu(false)}
        visible={collapsedMenu}
      >
        <div className="gx-layout-sider-header">
          <Link href="/">
            <a>
              <Image
                alt=""
                width={88}
                height={29}
                src={'/images/logo-blue.png'}
              />
            </a>
          </Link>
        </div>
        <div className="gx-sidebar-content">
          <Scrollbars className="gx-layout-sider-scrollbar">
            <MenuContent
              className="mobile-navigation-bar"
              mode="inline"
              onClick={() => setCollapsedMenu(false)}
            />
          </Scrollbars>
        </div>
      </Drawer>
    </Layout.Sider>
  );
};
export default SideBar;
