import type { FC } from 'react';
import { useCallback } from 'react';
import { Form, Input, Modal, notification } from 'antd';
import { useTranslation } from 'next-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAPIContext } from 'src/util/context/APIContext';
import { useBinderContext } from 'src/util/context/BinderContext';
import { QUERIES } from 'src/util/globals';

type FormValues = {
  name: string;
};

export const CreateBinder: FC = () => {
  const { createBinderModalVisible, toggleCreateBinderModal } =
    useBinderContext();
  const { t } = useTranslation('binders');
  const [form] = Form.useForm<FormValues>();
  const client = useQueryClient();
  const { API } = useAPIContext();

  const { mutateAsync: createBinder, isLoading: binderCreationProgress } =
    useMutation(
      async (name: string) => {
        return (
          await API?.bindersAPI.bindersCreate({
            binderRequest: {
              name,
            },
          })
        )?.data;
      },
      {
        onSuccess: () => {
          client.invalidateQueries([QUERIES.BINDERS]);
          toggleCreateBinderModal();
          notification.success({
            message: t('Binder has been created'),
          });
        },
      }
    );

  const handleSubmit = useCallback(async () => {
    const values = await form.validateFields();

    await createBinder(values.name);
  }, [createBinder, form]);

  return (
    <Modal
      visible={createBinderModalVisible}
      onCancel={() => toggleCreateBinderModal()}
      destroyOnClose
      afterClose={() => form.resetFields()}
      onOk={handleSubmit}
      okButtonProps={{ loading: binderCreationProgress }}
      okText={t('Create')}
      title={t(`Create new binder`)}
    >
      <Form form={form} layout="vertical" autoComplete="off">
        <Form.Item
          name="name"
          label={t('Binder name')}
          rules={[{ required: true }]}
        >
          <Input size="large" placeholder="Name" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
