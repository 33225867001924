import { useToggle } from '@react-hookz/web';
import React, { createContext, useState, useContext, useMemo } from 'react';

import type {
  Article,
  Binder,
  BindersApiBindersItemsListRequest,
  BindersApiBindersListRequest,
  ComplianceEntityResult,
} from 'src/api-sdk';
import { CreateBinder } from 'src/components/Binders/CreateBinder';
import { AddToBinder } from 'src/components/Binders/AddToBinder';
import { RemoveFromBinder } from 'src/components/Binders/RemoveFromBinder';
import { RemoveBinder } from 'src/components/Binders/RemoveBinder/RemoveBinder';
import { EditBinder } from 'src/components/Binders/EditBinder';
import { Notes } from 'src/components/Notes';
import { AddBinderItemTagsModal } from 'src/components/Binders/AddBinderItemTags';

export type BinderData = Pick<Binder, 'external_id' | 'name'>;
export type ArticleData = Pick<Article, 'external_id' | 'title'>;

export type BinderFilters = {
  searchQuery: string | undefined;
  sorting: BindersApiBindersItemsListRequest['sorting'];
  tags: string[];
};

export type BindersFilters = {
  searchQuery?: string | undefined;
  sorting?: BindersApiBindersListRequest['sorting'];
  sortingOrder?: BindersApiBindersListRequest['sortingOrder'];
  ownedBinders?: boolean;
};

type BinderContextType = {
  binderData: BinderData | null;
  setBinderData: (val: BinderData | null) => void;
  articleData: ArticleData | null;
  complianceEntityResult: ComplianceEntityResult | null;
  setArticleData: (val: ArticleData | null) => void;
  setComplianceEntityResult: (val: ComplianceEntityResult | null) => void;
  createBinderModalVisible: boolean;
  toggleCreateBinderModal: (v?: boolean) => void;
  addToBinderModalVisible: boolean;
  toggleAddToBinderModal: (v?: boolean) => void;
  removeFromBinderModalVisible: boolean;
  toggleRemoveFromBinderModalVisibility: (v?: boolean) => void;
  removeBinderModalVisible: boolean;
  toggleRemoveBinderModalVisibility: (v?: boolean) => void;
  editBinderModalVisible: boolean;
  toggleEditBinderModalVisibility: (v?: boolean) => void;
  binderPermissionsModalVisible: boolean;
  toggleBinderPermissionsModalVisibility: (v?: boolean) => void;
  binderItemNotesModalVisible: boolean;
  toggleBinderItemNotesModalVisible: (v?: boolean) => void;
  binderItemTagsModalVisible: boolean;
  toggleBinderItemTagsModalVisible: (v?: boolean) => void;
  filters: BinderFilters;
  setFilters: (val: BinderFilters) => void;
  bindersFilters: BindersFilters;
  setBindersFilters: (val: BindersFilters) => void;
  binderId: string | null;
  setBinderId: (val: string | null) => void;
  excludeBinderId: string | null;
  setExcludeBinderId: (val: string | null) => void;
  binderItemId: string | null;
  setBinderItemId: (val: string | null) => void;
  totalBinderItems: number;
  setTotalBinderItems: (val: number) => void;
  isBinderSummaryModalVisible: boolean;
  toggleBinderSummaryModalVisibility: (v?: boolean) => void;
};

export const BinderContext = createContext<BinderContextType | null>(null);

export const useBinderContext = (): BinderContextType => {
  const binderContext = useContext(BinderContext);

  if (!binderContext) {
    throw new Error(
      'binderContext must be used within a BinderContextProvider'
    );
  }

  return binderContext;
};

export const BinderContextProvider: React.FC = ({ children }) => {
  const [binderData, setBinderData] = useState<BinderData | null>(null);
  const [filters, setFilters] = useState<BinderFilters>({
    searchQuery: '',
    sorting: 'creation_date',
    tags: [],
  });
  const [bindersFilters, setBindersFilters] = useState<BindersFilters>({
    searchQuery: '',
    sorting: 'alphabetical',
    sortingOrder: 'descending',
    ownedBinders: false,
  });
  const [binderId, setBinderId] = useState<string | null>(null);
  const [binderItemId, setBinderItemId] = useState<string | null>(null);
  const [excludeBinderId, setExcludeBinderId] = useState<string | null>(null);
  const [articleData, setArticleData] = useState<ArticleData | null>(null);
  const [complianceEntityResult, setComplianceEntityResult] =
    useState<ComplianceEntityResult | null>(null);
  const [createBinderModalVisible, toggleCreateBinderModal] = useToggle(false);
  const [isBinderSummaryModalVisible, toggleBinderSummaryModalVisibility] =
    useToggle(false);
  const [addToBinderModalVisible, toggleAddToBinderModal] = useToggle(false);
  const [removeFromBinderModalVisible, toggleRemoveFromBinderModalVisibility] =
    useToggle(false);
  const [removeBinderModalVisible, toggleRemoveBinderModalVisibility] =
    useToggle(false);
  const [editBinderModalVisible, toggleEditBinderModalVisibility] =
    useToggle(false);
  const [binderItemNotesModalVisible, toggleBinderItemNotesModalVisible] =
    useToggle(false);
  const [binderItemTagsModalVisible, toggleBinderItemTagsModalVisible] =
    useToggle(false);
  const [
    binderPermissionsModalVisible,
    toggleBinderPermissionsModalVisibility,
  ] = useToggle(false);
  const [totalBinderItems, setTotalBinderItems] = useState(0);

  const value = useMemo<BinderContextType>(() => {
    return {
      binderData,
      setBinderData,
      articleData,
      setComplianceEntityResult,
      setArticleData,
      complianceEntityResult,
      createBinderModalVisible,
      toggleCreateBinderModal,
      binderItemTagsModalVisible,
      toggleBinderItemTagsModalVisible,
      addToBinderModalVisible,
      toggleAddToBinderModal,
      removeFromBinderModalVisible,
      toggleRemoveFromBinderModalVisibility,
      removeBinderModalVisible,
      toggleRemoveBinderModalVisibility,
      editBinderModalVisible,
      toggleEditBinderModalVisibility,
      binderPermissionsModalVisible,
      binderItemNotesModalVisible,
      toggleBinderItemNotesModalVisible,
      toggleBinderPermissionsModalVisibility,
      excludeBinderId,
      setExcludeBinderId,
      filters,
      setFilters,
      binderId,
      setBinderId,
      binderItemId,
      setBinderItemId,
      bindersFilters,
      setBindersFilters,
      isBinderSummaryModalVisible,
      toggleBinderSummaryModalVisibility,
      totalBinderItems,
      setTotalBinderItems,
    };
  }, [
    binderData,
    articleData,
    setComplianceEntityResult,
    setArticleData,
    complianceEntityResult,
    createBinderModalVisible,
    toggleCreateBinderModal,
    binderItemTagsModalVisible,
    toggleBinderItemTagsModalVisible,
    addToBinderModalVisible,
    toggleAddToBinderModal,
    removeFromBinderModalVisible,
    toggleRemoveFromBinderModalVisibility,
    removeBinderModalVisible,
    toggleRemoveBinderModalVisibility,
    editBinderModalVisible,
    toggleEditBinderModalVisibility,
    binderPermissionsModalVisible,
    binderItemNotesModalVisible,
    toggleBinderItemNotesModalVisible,
    toggleBinderPermissionsModalVisibility,
    totalBinderItems,
    setTotalBinderItems,
    excludeBinderId,
    filters,
    binderId,
    binderItemId,
    bindersFilters,
    isBinderSummaryModalVisible,
    toggleBinderSummaryModalVisibility,
    setBindersFilters,
  ]);

  return (
    <BinderContext.Provider value={value}>
      <>
        {children}
        <CreateBinder />
        <AddToBinder />
        <RemoveFromBinder />
        <RemoveBinder />
        <EditBinder />
        <Notes />
        <AddBinderItemTagsModal />
      </>
    </BinderContext.Provider>
  );
};
