import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { Form, Modal, notification, Select } from 'antd';
import { useTranslation } from 'next-i18next';
import { useDebouncedState } from '@react-hookz/web';
import { useQueryClient } from '@tanstack/react-query';
import TextArea from 'antd/lib/input/TextArea';

import { useBinderContext } from 'src/util/context/BinderContext';
import {
  useAddToBinder,
  useBindersList,
  useBinderTags,
} from 'src/hooks/useBinders';
import { usePaginatedData } from 'src/hooks/useNormalizePaginatedData';
import { QUERIES } from 'src/util/globals';

type FormValues = {
  binder: string;
  tags?: string[];
  note: string | undefined;
};

export const AddToBinder: FC = () => {
  const { t } = useTranslation('binders');
  const {
    addToBinderModalVisible,
    toggleAddToBinderModal,
    articleData,
    complianceEntityResult,
    excludeBinderId,
    setExcludeBinderId,
  } = useBinderContext();
  const [binderSearchQuery, setBinderSearchQuery] = useDebouncedState<string>(
    '',
    300
  );
  const [tagSearchQuery, setTagsSearchQuery] = useDebouncedState<string>(
    '',
    300
  );
  const [selectedBinderId, setSelectedBinderId] = useState<null | string>(null);

  const [form] = Form.useForm<FormValues>();
  const [open, setOpen] = useState<boolean>(false);
  const client = useQueryClient();
  const {
    data: bindersList,
    isLoading: bindersLoading,
    isFetching: bindersFetching,
  } = useBindersList(
    { searchQuery: binderSearchQuery },
    {
      enabled: addToBinderModalVisible,
    }
  );

  const binders = usePaginatedData(bindersList).results.filter(
    (item) => item.external_id !== excludeBinderId
  );

  const { data: binderTags, isLoading: binderTagsLoading } = useBinderTags({
    binderId: selectedBinderId!,
    query: tagSearchQuery,
    enabled: Boolean(addToBinderModalVisible && selectedBinderId),
  });

  const { results: tags } = usePaginatedData(binderTags);

  const { mutateAsync: addToBinder, isLoading: addToBinderProgress } =
    useAddToBinder({
      onSuccess: () => {
        client.invalidateQueries([QUERIES.BINDER_TAGS]);
        notification.success({
          message: t('Article was added to binder successfully!'),
        });
        toggleAddToBinderModal();
        setExcludeBinderId(null);
      },
    });

  const handleSubmit = useCallback(async () => {
    const values = await form.validateFields();

    await addToBinder({
      articleId: articleData ? articleData.external_id : null,
      complianceEntityResultId: complianceEntityResult
        ? complianceEntityResult.external_id
        : null,
      binderId: values.binder,
      tags: values.tags?.map((tag) => ({ text: tag })),
      note: values.note,
    });
  }, [addToBinder, articleData, complianceEntityResult, form]);

  return (
    <Modal
      visible={addToBinderModalVisible}
      onCancel={() => {
        setExcludeBinderId(null);
        toggleAddToBinderModal();
      }}
      destroyOnClose
      afterClose={() => {
        form.resetFields();
        setSelectedBinderId(null);
        setTagsSearchQuery('');
        setBinderSearchQuery('');
      }}
      onOk={handleSubmit}
      okButtonProps={{ loading: addToBinderProgress }}
      okText={t('Assign')}
      title={t(`Add to existing binder`)}
    >
      <Form form={form} layout="vertical" autoComplete="off">
        <Form.Item
          name="binder"
          label={t('Select Binder')}
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t('Search for binders')}
            className="mb-3 w-full"
            showSearch
            allowClear
            onClear={() => setSelectedBinderId(null)}
            onSearch={setBinderSearchQuery}
            disabled={addToBinderProgress}
            onChange={(value: string) => {
              value && setSelectedBinderId(value);
            }}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            loading={bindersLoading || bindersFetching}
          >
            {binders.map(({ name, external_id }) => (
              <Select.Option key={external_id} value={external_id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="tags" label={t('Tags')} rules={[{ required: false }]}>
          <Select
            placeholder={t('Search for tags')}
            className="mb-3 w-full"
            mode="tags"
            showSearch
            disabled={addToBinderProgress || !selectedBinderId}
            onSearch={setTagsSearchQuery}
            onClick={() => {
              setOpen(!open);
            }}
            onBlur={() => {
              setOpen(false);
            }}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            open={open}
            loading={binderTagsLoading || bindersFetching}
          >
            {tags.map(({ text, external_id }) => (
              <Select.Option key={external_id} value={text}>
                {text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="note" label={t('Note')}>
          <TextArea rows={4} placeholder={t('Note') + '...'} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
