import { Modal, notification, Typography } from 'antd';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useRemoveFromBinder } from 'src/hooks/useBinders';
import { useBinderContext } from 'src/util/context/BinderContext';
import { QUERIES } from 'src/util/globals';

export const RemoveFromBinder: FC = () => {
  const client = useQueryClient();
  const { t } = useTranslation('binders');
  const {
    removeFromBinderModalVisible,
    toggleRemoveFromBinderModalVisibility,
    binderData,
    articleData,
  } = useBinderContext();
  const { mutateAsync: removeFromBinder, isLoading: removeProgress } =
    useRemoveFromBinder({
      onSuccess: () => {
        client.invalidateQueries([QUERIES.BINDER_DATA]);
        notification.info({
          message: t(`Article has been removed from binder successfully!`),
        });
        toggleRemoveFromBinderModalVisibility();
      },
    });

  return (
    <Modal
      visible={removeFromBinderModalVisible}
      onCancel={() => toggleRemoveFromBinderModalVisibility()}
      destroyOnClose
      okText={t('Remove')}
      title={t('Remove from binder')}
      centered
      okButtonProps={{
        danger: false,
        // @ts-expect-error - this is a bug in antd
        type: 'danger',
        loading: removeProgress,
      }}
      onOk={() =>
        removeFromBinder({
          binderItemId: articleData!.external_id,
          binderId: binderData!.external_id,
        })
      }
    >
      <div className="text-center">
        <Typography.Title level={5}>
          {t('Are you sure you want to remove article from binder?')}
        </Typography.Title>
        <Typography.Text type="secondary" italic>
          {articleData?.title}
        </Typography.Text>
      </div>
    </Modal>
  );
};
